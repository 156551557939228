.App {
  text-align: center;
  width: 90%;
  padding-left: 5%;
  padding-top: 5%;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}

.icon {
  color: #40a9ff;
  font-size: 48px;
  margin: 0 0 20px 0;
}

.text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}

.hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
