.container {
  margin: 30px 0;
}

.content {
  width: 75%;
}

.header {
  font-weight: bold;
  font-size: 1.4em;
}

.notes {
  font-size: 0.85em;
}

.upload {
  margin: 0;
  background: #000;
  color: #fff;
  padding: 0 24px;
  font-size: 1em;
  width: 15%;
  line-height: 40px;
  font-weight: 300;
  border: none;
  cursor: pointer;
  border-radius: 2px;
}

th,
td {
  padding: 5px;
  border-bottom: 1px solid grey;
  border-collapse: collapse;
  font-size: 11px;
}
button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
.cancel {
  cursor: pointer;
  color: #2074bf;
  font-size: 0.9em;
  margin-left: 20px;
  margin-top: 10px;
  border: none;
  background: none;
}
.delete-icon {
  cursor: pointer;
}
.spreadsheet {
  margin-top: 3%;
  margin-bottom: 6%;
}

.title,
.addedTitle {
  font-weight: bold;
  margin-bottom: 16px;
}
.addedTitle {
  color: green;
}
.require {
  font-weight: lighter;
  font-weight: 0.8em;
  color: #676767;
}

.choosefile {
  width: 15%;
  background: #fff;
  color: #000;
  padding: 0 15px;
  font-size: 1em;
  line-height: 40px;
  font-weight: 300;
  border: 1px #000 solid;
  cursor: pointer;
  border-radius: 2px;
}
.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.filenote {
  font-size: 0.85em;
  margin-left: 10px;
}
.table {
  width: 100%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-collapse: collapse;
}
.action {
  margin-top: 2%;
  margin-bottom: 2%;
}
.progressbar {
  margin: auto;
  width: 40%;
  margin-top: 2%;
  margin-bottom: 2%;
}
.errorTxt {
  font-size: 0.85em;
  color: red;
}

.dropZone {
  margin-top: 5%;
  border: 1px solid #ccc;
  height: 100%;
  height: 300px;
}

.dropTxt {
  text-align: center;
  margin-top: 130px;
}

.preview {
  margin: 20px 0;
}

.rowCheck {
  margin-right: 20px;
}

.select {
  margin: 20px 0;
  width: 40%;
}

.deleteButton {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.fileInfo {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-left: 20%;
}

.uploading {
  margin: 0;
  background: gray;
  color: #fff;
  padding: 0 24px;
  font-size: 11px;
  width: 15%;
  line-height: 40px;
  font-weight: 300;
  border: none;
  cursor: not-allowed;
  border-radius: 2px;
}

.tooltip {
  height: 20px;
  position: absolute;
  right: 5px;
  width: 20px;
  bottom: 5px;
}

.toogle-container {
  display: flex;
  justify-content: center;
}
.toogle-label {
  display: flex;
  align-items: center;
  padding: 0px 5px;
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19ab27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128d15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19ab27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
  -moz-box-shadow: 0px 0px 3px 2px #0099e0;
  box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
  -moz-box-shadow: 0px 0px 5px 5px #0099e0;
  box-shadow: 0px 0px 5px 5px #0099e0;
}
